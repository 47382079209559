import classNames from 'classnames/bind';
import React from 'react';
import styles from './styles.module.scss';
import bball from '../../images/loaders/bball-small.gif';
import bballWhite from '../../images/loaders/bball-white-small.gif';

const cx = classNames.bind(styles);

type LoadingType = 'inline' | 'horizontal' | 'horizontal-w' | 'white' | 'simple';

type Props = {
  style?: React.CSSProperties | undefined;
  kind?: LoadingType;
  type?: LoadingType;
  message?: string;
  className?: string;
};

const Loader: React.FC<Props> = ({ kind, type, style, message, className }) => {
  const prop = kind || type;

  if (prop === 'inline') {
    return (
      <span {...{ style }} className={cx(styles.inline, className)}>
        <span>●</span>
        <span>●</span>
        <span>●</span>
      </span>
    );
  }

  let src: string | null = bball;

  switch (prop) {
    case 'horizontal':
    case 'horizontal-w':
    case 'white':
      src = bballWhite;
      break;
    case 'simple':
      src = null;
      break;
    default:
      src = bball;
  }

  return (
    <span>
      {src ? (
        <img style={style} className={cx('loader', className, prop)} alt="Loading..." src={src} />
      ) : (
        <div className={cx('simple-loader')} />
      )}
      {message ? <h2 className={cx(className, prop)}>{message}</h2> : null}
    </span>
  );
};

export const PageLoader = () => <Loader className="page-loader" />;

export const InlineLoader = () => <Loader kind="inline" />;

export default Loader;
