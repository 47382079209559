import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import { dashSpaceSwap } from '../../../../../utils/url-search-helpers';
import { DropdownLinkList } from '../components';
import styles from '../../../../../styles/main.module.scss';
import { StaticUrls } from '../../../../../shared/static-urls-enum';
import { CHUNK_BREAKPOINT, chunkWithBreakpoint } from '../../../../../shared/array-helpers';
import type { LinkItem } from '../components/DropdownLinkList';

const cx = classNames.bind(styles);

type InfoProps = {
  cityName: string;
  organizationSports: { sportName: string }[];
  hideSports: boolean;
  hideNav: boolean;
  isMobile: boolean;
  setHideSports: (hideSports: boolean) => void;
};

const Info: React.FC<InfoProps> = ({
  cityName,
  organizationSports,
  hideSports,
  hideNav,
  isMobile,
  setHideSports,
}) => {
  // New York has some additional entries
  const hasNYLeagues = ['New York Metro Area', 'Super Sportz'].includes(cityName);

  // Links should be in alphabetical order with Our Story and Our Athletes always at the top
  const aboutVoloLinks: LinkItem[] = [
    { name: 'Our Story', href: '/about-us' },
    { name: 'Our Athletes', href: StaticUrls.ATHLETES },
    { name: 'Careers', href: StaticUrls.CAREERS },
    { name: 'Contact Us', href: '/contact-us' },
    {
      name: 'Corporate',
      href: cityName ? `/${dashSpaceSwap(cityName, 'toDash')}/corporate` : '/corporate',
    },
    ...(hasNYLeagues ? [{ name: 'Corporate League', href: StaticUrls.NY_CORPORATE_LEAGUE }] : []),
    { name: 'FAQs', href: '/faq' },
    ...(hasNYLeagues ? [{ name: 'Lawyers League', href: StaticUrls.NY_LAWYERS_LEAGUE }] : []),
    { name: 'Partnerships', href: StaticUrls.PARTNERSHIPS },
    { name: 'Policies', href: '/policies' },
    { name: 'Rules', href: cityName ? `/${dashSpaceSwap(cityName, 'toDash')}/rules` : '/rules' },
    { name: 'Volunteer', href: StaticUrls.VOLOKIDS_VOLUNTEER },
  ];

  return (
    <div className={cx(hideNav && 'small-hide-col', 'col-12', 'col-md-auto', 'my-auto')}>
      <div className={cx('dropdownButton', 'navCol')}>
        Info
        <div
          className={cx(
            'dropdown',
            cityName && organizationSports?.length > CHUNK_BREAKPOINT && 'pull-left'
          )}
        >
          <div className="row">
            {cityName && (
              <div className="col-12 col-md-auto">
                <h4>
                  About Our Sports
                  {isMobile && (
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() => setHideSports(!hideSports)}
                      onKeyDown={() => setHideSports(!hideSports)}
                      className="text-muted ml-2"
                    >
                      ({hideSports ? 'show' : 'hide'})
                    </span>
                  )}
                </h4>

                {!hideSports && (
                  <div className="row">
                    {chunkWithBreakpoint(organizationSports).map(batch => (
                      <div className="col" key={batch.map(b => b.sportName).join(',')}>
                        <ul className={cx('innerList')}>
                          {batch.map(({ sportName }) => (
                            <li key={sportName}>
                              <Link
                                to={{
                                  pathname: `/${dashSpaceSwap(cityName, 'toDash')}/${dashSpaceSwap(
                                    sportName,
                                    'toDash'
                                  )}`,
                                }}
                              >
                                {sportName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            <DropdownLinkList heading="About Volo" links={aboutVoloLinks} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
