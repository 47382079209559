import { gql } from '@apollo/client';

export const REGISTER_FOR_PROGRAM = gql`
  mutation currentUserRegisterForProgram($input: CurrentUserRegisterForProgramInput!) {
    currentUserRegisterForProgram(input: $input) {
      programRegistration {
        registrant {
          _id
        }
      }
    }
  }
`;

export const REGISTER_FOR_DROP_IN = gql`
  mutation currentUserConfirmDropin($input: CurrentUserConfirmDropinInput!) {
    currentUserConfirmDropin(input: $input) {
      errored
      registrant {
        _id
      }
    }
  }
`;

export const GET_DROP_IN_SLOT = gql`
  query DropInSlot($id: ID!) {
    dropInSlot(_id: $id) {
      gameId
    }
  }
`;

export const GET_GAME_DETAILS = gql`
  query game($gameId: ID!) {
    game(_id: $gameId) {
      _id
      end_time
      start_time
      dropInCapacity {
        a
        f
        m
      }
      league {
        _id
        displayName
        startDateStr
        header
        photoUrl
        isDaily
        organization {
          name
          icon
          isVoloPassTrialActive
          voloPassTiers {
            isActive
            monthlyDollarPrice
            monthlyFee
            monthlyPlanId
          }
        }
        venue {
          name
          formatted_address
        }
      }
      startTimeStr
      endTimeStr
      location {
        _id
        name
        timezone
        neighborhood
        city
      }
      field_name
      teams {
        _id
        name
      }
      teamCapacities {
        teamId
        dropInSlots {
          gender
        }
      }
    }
  }
`;

export const IS_IN_PROGRAM = gql`
  query currentUserIsInProgram($input: CurrentUserIsInProgramInput!) {
    currentUserIsInProgram(input: $input) {
      isInProgram
    }
  }
`;

export const MY_DROPIN_RSVPS = gql`
  query myRsvps($pagination: PaginationInput!) {
    myRsvps(pagination: $pagination) {
      rsvps {
        gameId
      }
    }
  }
`;

export const USER_QUERY = gql`
  query currentUser {
    currentUser {
      _id
      membershipExpires
      homeOrganization
      roles
      leagueConnections {
        team {
          _id
          name
        }
      }
      paymentSources {
        isDefault
        isExpired
        last4
      }
    }
  }
`;

export const GET_LEAGUE_DETAILS = gql`
  query league($leagueId: ID!) {
    league(_id: $leagueId) {
      _id
      name
      deepLink
      programType
      displayName
      isDaily
      divisionDescriptions
      donationsDisabled
      registrantCount
      header
      photoUrl
      startDateStr
      start_time_estimate
      end_time_estimate
      details {
        description
      }
      registration {
        registration_open
        registration_close
        minSize
        maxSize
      }
      venue {
        _id
        name
        formatted_address
        neighborhood
        timezone
        city
      }
      schedules {
        _id
        startDateStr
        startTimeStr
        endTimeStr
      }
      organization {
        _id
        isVoloPassTrialActive
        voloPassTiers {
          isActive
          monthlyDollarPrice
          monthlyFee
          monthlyPlanId
        }
        approvedVenues {
          _id
          name
          formatted_address
          neighborhood
          timezone
          city
        }
      }
    }
  }
`;

export const GET_PROGRAM_ROSTER = gql`
  query programRoster($leagueId: ID!) {
    programRoster(leagueId: $leagueId) {
      userId
      gender
      picture
      userDisplayInfo {
        displayName
        displayEmail
        displayPhone
      }
    }
  }
`;
