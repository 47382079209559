import DiscoverTabs, {
  SubTabEnum,
  isSubTabValid,
  MainTabEnum,
} from '../../../shared/discover-tabs-enum';
import updateFilters from '../utils/updateFilters';
import useDiscoverStore from '../DiscoverStore';
import initialDiscoverStore from '../initialDiscoverStore';
import getProgramTypesFromTabs from '../utils/getProgramTypesFromTabs';

const {
  cityName: initialCityName,
  dateLow: initialDateLow,
  dateHigh: initialDateHigh,
  timeLow: initialTimeLow,
  timeHigh: initialTimeHigh,
  programTagIds: initialTags,
  selectedGenders: initialGenders,
  selectedVenueIds: initialVenueIds,
  selectedDaysOfWeek: initialDays,
  selectedSportNames: initialSportNames,
  selectedSkillLevels: initialSkillLevels,
  selectedProgramTypes: initialProgramTypes,
  selectedNeighborhoodIds: initialNeighborhoodIds,
  minimumMen: initialMinimumMen,
  minimumWomen: initialMinimumWomen,
  minimumNonBinary: initialMinimumNonBinary,
} = initialDiscoverStore.filters;

const updateStoreWithUrlParams = urlParams => {
  const {
    cityName,
    view,
    subView,
    dateLow,
    dateHigh,
    timeLow,
    timeHigh,
    tags,
    genders,
    venueIds,
    sportNames,
    daysOfWeek,
    skillLevels,
    programTypes,
    neighborhoodIds,
    minimumMen: minimumMenString,
    minimumWomen: minimumWomenString,
    minimumNonBinary: minimumNonBinaryString,
  } = urlParams;

  const set = useDiscoverStore.setState;
  const get = useDiscoverStore.getState;

  // reset when new filters are added
  set({ currentPage: 1 });

  if (view) {
    const finalSubView = isSubTabValid(view, subView) ? subView : DiscoverTabs[view].subTabs[0];
    // isSubTabValid considers if !subView
    set({
      view,
      subView: finalSubView,
      isDaily: finalSubView === SubTabEnum.DAILY || (view === MainTabEnum.EVENTS && !finalSubView),
      defaultProgramTypes: getProgramTypesFromTabs(view, finalSubView),
    });
  }

  if (!view && subView) {
    const { view: currentStoreView } = get();
    const finalSubView = isSubTabValid(currentStoreView, subView)
      ? subView
      : DiscoverTabs[currentStoreView].subTabs[0];
    set({
      view: currentStoreView,
      subView: finalSubView,
      isDaily: finalSubView === SubTabEnum.DAILY,
      defaultProgramTypes: getProgramTypesFromTabs(currentStoreView, finalSubView),
    });
  }

  const minimumMen = parseInt(minimumMenString, 10);
  const minimumWomen = parseInt(minimumWomenString, 10);
  const minimumNonBinary = parseInt(minimumNonBinaryString, 10);

  updateFilters({
    ...(cityName ? { cityName } : { cityName: initialCityName }),
    ...(timeLow ? { timeLow } : { timeLow: initialTimeLow }),
    ...(timeHigh ? { timeHigh } : { timeHigh: initialTimeHigh }),
    ...(tags ? { programTagIds: tags } : { programTagIds: initialTags }),
    ...(genders ? { selectedGenders: genders } : { selectedGenders: initialGenders }),
    ...(venueIds ? { selectedVenueIds: venueIds } : { selectedVenueIds: initialVenueIds }),
    ...(daysOfWeek ? { selectedDaysOfWeek: daysOfWeek } : { selectedDaysOfWeek: initialDays }),
    ...(sportNames
      ? { selectedSportNames: sportNames }
      : { selectedSportNames: initialSportNames }),
    ...(skillLevels
      ? { selectedSkillLevels: skillLevels }
      : { selectedSkillLevels: initialSkillLevels }),
    ...(programTypes
      ? { selectedProgramTypes: programTypes }
      : { selectedProgramTypes: initialProgramTypes }),
    ...(neighborhoodIds
      ? { selectedNeighborhoodIds: neighborhoodIds }
      : { selectedNeighborhoodIds: initialNeighborhoodIds }),
    ...(minimumMen ? { minimumMen } : { minimumMen: initialMinimumMen }),
    ...(minimumWomen ? { minimumWomen } : { minimumWomen: initialMinimumWomen }),
    ...(minimumNonBinary ? { minimumNonBinary } : { minimumNonBinary: initialMinimumNonBinary }),

    ...(dateLow
      ? {
          ...(dateHigh // if dateHigh doesn't exist, set dateHigh to dateLow
            ? { dateLow }
            : { dateLow, dateHigh: dateLow }),
        }
      : { dateLow: initialDateLow, dateHigh: initialDateHigh }),
    ...(dateHigh
      ? {
          ...(dateLow ? { dateHigh } : { dateLow: dateHigh, dateHigh }), // if dateLow doesn't exist, set dateLow to dateHigh
        }
      : { dateLow: initialDateLow, dateHigh: initialDateHigh }),
  });
};

export default updateStoreWithUrlParams;
