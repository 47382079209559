import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import styles from './tooltip.module.scss';

const Tooltip = ({ maxWidth, small, className, ...props }) => (
  <ReactTooltip
    className={cx(styles.tooltip, styles[props.type], className, {
      small,
    })}
    style={{ ...(maxWidth ? { maxWidth } : {}) }}
    {...props}
  />
);

Tooltip.propTypes = {
  // id must match the data-for prop on the trigger component.
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([null, 'dark', 'success', 'warning', 'error', 'info', 'light']),
  place: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
  effect: PropTypes.oneOf(['solid', 'float']),
  multiline: PropTypes.bool,
  maxWidth: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  disable: PropTypes.bool,
};

Tooltip.defaultProps = {
  type: 'dark',
  effect: 'solid',
  multiline: true,
  maxWidth: false,
  className: '',
  small: false,
  place: null,
  disable: false,
  // event: 'click',
};

export default Tooltip;
