// import { isProduction as isProdServer } from '/server/tools/serverConfig';
// import { isProduction as isProdClient } from '/client/environment';

// export const STRIPE_VOLO_PASS_PRODUCT_IDS = isProdClient || isProdServer
//   ? [
//     'prod_LITLNLTQyFSivl', // Stripe Name: "Volo Pass"
//     'prod_NVG4P6j069WhXs', // "Volo Pass NYC PT Staff Membership"
//     'prod_HtYy8GGEKYArWh', // "Volo Pass Monthly"
//     'prod_F8LERRtiSSoC7B', // "VoloPass - Monthly"
//   ]
//   : ['prod_F8QWiJBlmvvEl7']; // "Membership 999"

// /** @type {(productId: string) => boolean} */
// export const productIdIsVoloPass = productId => STRIPE_VOLO_PASS_PRODUCT_IDS.includes(productId);

// Stripe does not export its enums so we must manually define them here.
export const StripeInvoiceStatus = Object.freeze({
  DRAFT: 'draft',
  OPEN: 'open',
  PAID: 'paid',
  UNCOLLECTIBLE: 'uncollectible',
  VOID: 'void',
});

export const StripeDiscountDuration = Object.freeze({
  ONCE: 'once',
  REPEATING: 'repeating',
  FOREVER: 'forever',
});

export const StripeSubscriptionStatus = Object.freeze({
  ACTIVE: 'active',
  TRIALING: 'trialing',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
});

export const StripeTaxRates = Object.freeze({
  // Tech Fees in Production
  price_1KbsOjCyzbeSckFnDm3WXS8P: 'txr_1KLVw3CyzbeSckFn7BVCWON2',
  price_1KbsOjCyzbeSckFns5tgZmeu: 'txr_1KLVv3CyzbeSckFnG0Wv4UK8',
  price_1KbsOjCyzbeSckFnhR8Hrrim: 'txr_1KLVtTCyzbeSckFnvOasTO0S',
  price_1KbsOjCyzbeSckFnap86C811: 'txr_1KHaKsCyzbeSckFnx7glgmX9',
  price_1KbsOjCyzbeSckFnXqlXcgti: 'txr_1KHaK6CyzbeSckFnOOjcWD2R',
  price_1KbsOjCyzbeSckFneFclgfTt: 'txr_1KLUTLCyzbeSckFnP4ziP8sX',
  price_1KbsOjCyzbeSckFnnVsLwv0R: 'txr_1KHaHsCyzbeSckFn33HtfNJc',
  price_1KbsOjCyzbeSckFnLPNo510C: 'txr_1KLUFyCyzbeSckFnMEljV0kv',
  price_1KbsOjCyzbeSckFnPz0JgFXe: 'txr_1KHaGACyzbeSckFnwi4aFITA',
  price_1KbsOjCyzbeSckFnGIsnrTLk: 'txr_1KLUEMCyzbeSckFnNlE0E4tA',
  price_1KbsOjCyzbeSckFnaWjGYSKQ: 'txr_1KHaBaCyzbeSckFnAANl5N1h',
  price_1KbsOjCyzbeSckFn7E8xImha: 'txr_1KHa9sCyzbeSckFnrRnbAb6n',
  price_1KbsOjCyzbeSckFn6ZLYuMjl: 'txr_1KHa4sCyzbeSckFnsPQzEH3l',

  // Flat 1.5% fee in staging
  STAGING: 'txr_1K47vRCyzbeSckFnhuocqEPw',
});
