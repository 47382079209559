import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import GET_GAME_DETAILS from './graphql/GET_GAME_DETAILS';
import GET_PROGRAM_DETAILS from './graphql/GET_PROGRAM_DETAILS';
import GET_PROGRAM_PRICING from './graphql/GET_PROGRAM_PRICING';
import useDropInCapacity from '../useDropInCapacity';

const useDailyProgramDetails = ({ gameId, programId }) => {
  const {
    data: gameData,
    loading: gameLoading,
    error: gameError,
  } = useQuery(GET_GAME_DETAILS, {
    skip: !gameId,
    variables: { id: gameId },
  });

  const game = gameData?.game || {};
  const gameLeagueId = game?.league?._id;

  const {
    data: programData,
    loading: programLoading,
    error: programError,
  } = useQuery(GET_PROGRAM_DETAILS, {
    skip: !programId && !gameLeagueId,
    variables: { id: programId || gameLeagueId },
  });

  const {
    data: pricingData,
    loading: pricingLoading,
    error: pricingError,
  } = useQuery(GET_PROGRAM_PRICING, {
    skip: !programId && !gameLeagueId,
    variables: {
      input: {
        leagueId: programId || gameLeagueId,
        isPrepaidTeam: false,
      },
    },
  });

  const { capacity } = useDropInCapacity({ gameId });

  const program = programData?.league || {};
  const displayName = program?.displayName || '';
  const programType = program?.programType || '';
  const imageUrl = program?.header || '';
  const bannerText = program?.banner_text || '';
  const registrantCount = program?.registrantCount || 0;
  const registrationMin = program?.registration?.minSize || 0;
  const registrationMax = program?.registration?.maxSize || 0;
  const startTimeStr = program?.start_time_estimate || '';
  const endTimeStr = program?.end_time_estimate || '';
  const programStartTime = moment(startTimeStr, 'HH:mm').format();
  const programEndTime = moment(endTimeStr, 'HH:mm').format();
  const fallbackProgramVenue = program?.venue || {};
  const fallbackProgramNeighborhood = fallbackProgramVenue?.neighborhood;
  const fallbackProgramVenueName = fallbackProgramVenue?.shorthandName;
  const fallbackProgramVenueAddress = fallbackProgramVenue?.formatted_address;
  const fallbackProgramVenueTimezone = fallbackProgramVenue?.timezone;
  const fallbackProgramSportName = program?.sport?.name;

  /* game times */
  const startTime = game?.start_time || programStartTime;
  const endTime = game?.end_time || programEndTime;

  /* game location */
  const neighborhood = game?.location?.neighborhood || fallbackProgramNeighborhood;
  const venueName = game?.location?.name || fallbackProgramVenueName;
  const venueAddress = game?.location?.formatted_address || fallbackProgramVenueAddress;
  const venueCoordinates = fallbackProgramVenue?.location;
  const timezone = game?.location?.timezone || fallbackProgramVenueTimezone;
  /* league info */
  const sportName = game?.league?.sport?.name || fallbackProgramSportName;

  /* pricing */
  const memberPrice = pricingData?.programPricingForRegistration?.pricingBreakdown?.memberPrice;
  const nonMemberPrice =
    pricingData?.programPricingForRegistration?.pricingBreakdown?.originalPrice;

  return {
    data: {
      endTime,
      startTime,
      imageUrl,
      timezone,
      sportName,
      venueName,
      venueAddress,
      venueCoordinates,
      programType,
      displayName,
      bannerText,
      neighborhood,
      registrantCount,
      registrationMin,
      registrationMax,
      memberPrice,
      nonMemberPrice,
      formattedRegistrationCount: `${registrantCount}/${registrationMax}`,
      dropIn: {
        capacityBreakdown: {
          any: capacity.any,
          female: capacity.female,
          male: capacity.male,
        },
        totalCapacity: capacity.total,
      },
    },
    loading: gameLoading || programLoading || pricingLoading,
    error: gameError || programError || pricingError,
  };
};

export default useDailyProgramDetails;
