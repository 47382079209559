import { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { useActionStates } from '..';
import { omitDeepTypename } from '../../utils/pass-graphql';
import { RegistrationContext } from '../../contexts';
import { RegistrationActions } from '../../contexts/RegistrationContext/RegistrationReducer';
import { membershipPlanId } from '../../environment';
import { getToday, addDays } from '../../shared/date-helpers';
import {
  GET_GAME_DETAILS,
  GET_LEAGUE_DETAILS,
  GET_DROP_IN_SLOT,
  IS_IN_PROGRAM,
  REGISTER_FOR_PROGRAM,
  REGISTER_FOR_DROP_IN,
  USER_QUERY,
  MY_DROPIN_RSVPS,
  GET_PROGRAM_ROSTER,
} from './QUERIES_AND_MUTATIONS';
import {
  ACTIVATE_MEMBERSHIP,
  START_TRIAL_MEMBERSHIP,
  UPDATE_USER_PLAN,
} from '../useVoloPassDetails/QUERIES';
import { MembershipTrialEnum } from '../../shared/membership-enums';
import { PlatformEnum } from '../../shared/platform-enum';

const { UPDATE_REGISTRATION_STATE } = RegistrationActions;

const useRegistrationData = () => {
  const { dropInId, leagueId: dailyLeagueId, gameId: dropInGameId, invite } = useParams();

  const {
    updating: registerUpdating,
    setSuccess,
    setError,
    setUpdating,
  } = useActionStates({ withAlerts: true });

  const [registerState, registerDispatch] = useContext(RegistrationContext);

  const {
    selectedTeam,
    membershipExpires,
    addVpTrial,
    addVpMembership,
    appliedPromo,
    donationAmount,
    dropInPriceData,
    creditAppliedAmount,
  } = registerState;

  const dropInPricingOptions = dropInPriceData?.dropinPricingForRegistration ?? {};
  const { currentUserPaymentCriteria } = dropInPricingOptions;
  const dropinBreakdown =
    addVpMembership || addVpTrial ? 'memberBreakdown' : currentUserPaymentCriteria;
  const dropInPricingBreakdown = dropInPricingOptions?.[dropinBreakdown];

  const [league, setLeague] = useState(null);
  const [registrationClosed, setRegistrationClosed] = useState(false);

  const [registerMutation] = useMutation(REGISTER_FOR_PROGRAM);
  const [dropInMutation] = useMutation(REGISTER_FOR_DROP_IN);
  const [startTrialMembershipMutation] = useMutation(START_TRIAL_MEMBERSHIP);
  const [startMembershipMutation] = useMutation(ACTIVATE_MEMBERSHIP);
  const [updateUserPlanMutation] = useMutation(UPDATE_USER_PLAN);

  const { data: userData, loading: userLoading, error: userError } = useQuery(USER_QUERY);
  const { currentUser } = userData || {};

  const {
    data: dropInRsvpsData,
    loading: dropInRsvpLoading,
    error: dropInRsvpError,
    refetch: dropInRsvpRefetch,
  } = useQuery(MY_DROPIN_RSVPS, {
    variables: {
      pagination: {
        numPerPage: 100,
        pageNum: 1,
      },
    },
  });
  const { myRsvps } = dropInRsvpsData || {};

  const {
    data: dropInData,
    loading: dropInLoading,
    error: dropInError,
  } = useQuery(GET_DROP_IN_SLOT, {
    skip: !dropInId,
    variables: {
      id: dropInId,
    },
  });

  const {
    data: gameData,
    loading: gameLoading,
    error: gameError,
  } = useQuery(GET_GAME_DETAILS, {
    skip: !(dropInGameId || dropInData?.dropInSlot?.gameId),
    variables: {
      gameId: dropInGameId || dropInData?.dropInSlot?.gameId,
    },
  });

  const { game } = gameData || {};

  const {
    data: leagueData,
    loading: leagueLoading,
    error: leagueError,
  } = useQuery(GET_LEAGUE_DETAILS, {
    skip: !(dailyLeagueId || game?.league?._id),
    variables: {
      leagueId: dailyLeagueId || game?.league?._id,
    },
  });

  useEffect(() => {
    if (!league && (leagueData || gameData)) {
      setLeague(() => omitDeepTypename(leagueData?.league || game?.league));
    }
    const registrationOpenDate = !game?._id && leagueData?.league?.registration?.registration_open;
    const registrationCloseDate =
      !game?._id && leagueData?.league?.registration?.registration_close;
    // daily: registration closed if league date is before reg_open & after reg_close
    if (
      new Date().toISOString() < registrationOpenDate ||
      new Date().toISOString() > registrationCloseDate
    ) {
      setRegistrationClosed(true);
    }
    // 11/2023 #396 - users can register for drop-ins until the game has ended
    if (game?._id && new Date().toISOString() > game?.end_time) {
      setRegistrationClosed(true);
    }
    // allow for additional registrations with a group link before start date
    if (invite && new Date().toISOString() < league?.start_date) setRegistrationClosed(false);
  }, [game, gameData, leagueData, league, invite]);

  const {
    data: enrollmentData,
    loading: enrollmentLoading,
    error: enrollmentError,
    refetch: enrollmentRefetch,
  } = useQuery(IS_IN_PROGRAM, {
    skip: !currentUser?._id || !(dailyLeagueId || game?.league?._id),
    variables: {
      input: { leagueId: dailyLeagueId || game?.league?._id },
    },
  });

  const {
    data: programRosterData,
    error: programRosterError,
    refetch: programRosterRefetch,
  } = useQuery(GET_PROGRAM_ROSTER, {
    fetchPolicy: 'cache-and-network',
    skip: !(dailyLeagueId || game?.league?._id) || !currentUser,
    variables: {
      leagueId: dailyLeagueId || game?.league?._id,
    },
  });

  const { programRoster } = programRosterData || {};

  const isMember = currentUser?._id && currentUser?.roles.includes('MEMBER');
  const isVoloPassTrialActive = !game?._id
    ? league?.organization?.isVoloPassTrialActive
    : game?.league?.organization?.isVoloPassTrialActive;
  const monthlyDollarPrice = !game?._id
    ? league?.organization?.voloPassTiers?.monthlyDollarPrice
    : game?.league?.organization?.voloPassTiers?.monthlyDollarPrice;
  const monthlyFee = !game?._id
    ? league?.organization?.voloPassTiers?.monthlyFee
    : game?.league?.organization?.voloPassTiers?.monthlyFee;
  const monthlyPlanId = !game?._id
    ? league?.organization?.voloPassTiers?.monthlyPlanId
    : game?.league?.organization?.voloPassTiers?.monthlyPlanId;
  const isActive = !game?._id
    ? league?.organization?.voloPassTiers?.isActive
    : game?.league?.organization?.voloPassTiers?.isActive;
  const cityName = !game?._id ? league?.venue?.city : game?.location?.city;

  const isEnrolled = enrollmentData?.currentUserIsInProgram?.isInProgram;

  const isRsvpdForDropIn = myRsvps?.rsvps.some(r => r?.gameId === game?._id);

  const isEnrolledInProgram = isEnrolled || isRsvpdForDropIn;

  const today = getToday();
  const twoWeeksLater = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  }).format(addDays(today, 14));

  const isEnrolledRefetch = async () => {
    try {
      await enrollmentRefetch();
      await dropInRsvpRefetch();
    } catch (e) {
      setError(e);
    }
  };

  const onStartVpClick = useCallback(async () => {
    try {
      if (league?.organization?.isVoloPassTrialActive && !membershipExpires) {
        registerDispatch({
          type: UPDATE_REGISTRATION_STATE,
          update: {
            addVpTrial: true,
          },
        });
      } else {
        registerDispatch({
          type: UPDATE_REGISTRATION_STATE,
          update: {
            addVpMembership: true,
          },
        });
      }
    } catch (e) {
      setError(e);
    } finally {
      setSuccess('Volo Pass added to cart!');
    }
  }, [
    league?.organization?.isVoloPassTrialActive,
    membershipExpires,
    registerDispatch,
    setError,
    setSuccess,
  ]);

  const removeVpClick = useCallback(async () => {
    try {
      registerDispatch({
        type: UPDATE_REGISTRATION_STATE,
        update: {
          addVpTrial: false,
          addVpMembership: false,
          showConfirmation: false,
          showVpPopup: false,
        },
      });
    } catch (e) {
      setError(e);
    } finally {
      setSuccess('Volo Pass removed from cart');
    }
  }, [registerDispatch, setSuccess, setError]);

  const confirmDropInRsvp = async () => {
    try {
      setUpdating(true);
      registerDispatch({
        update: {
          processingPayment: true,
          leagueId: league?._id || game?.league?._id,
        },
        type: UPDATE_REGISTRATION_STATE,
      });
      if (addVpTrial) {
        await startTrialMembershipMutation({
          variables: {
            input: {
              planId: monthlyPlanId || membershipPlanId,
              trialType: MembershipTrialEnum.TWO_WEEKS,
            },
          },
        });
      }
      if (addVpMembership) {
        await startMembershipMutation({
          variables: {
            input: {
              planId: monthlyPlanId || membershipPlanId,
            },
          },
        });
        await updateUserPlanMutation({ variables: { input: { chosenPlan: 'pass' } } });
      }
      await dropInMutation({
        variables: {
          input: {
            platform: PlatformEnum.WEB,
            dropInSlotId: dropInId,
            gameId: game?._id,
            donationCents: donationAmount,
            creditCents: Math.min(creditAppliedAmount, dropInPricingBreakdown?.programPriceCents),
            waiverSigned: true,
            cancellationPolicyAgreed: true,
            promoCodeStr: appliedPromo,
            teamId: selectedTeam?._id,
            vpInCart: addVpMembership || addVpTrial,
          },
        },
      });
      registerDispatch({
        type: UPDATE_REGISTRATION_STATE,
        update: { showConfirmation: true },
      });
      setSuccess('Successfully RSVPed for drop-in.');
      await isEnrolledRefetch();
    } catch (e) {
      setError(e);
    } finally {
      registerDispatch({
        update: { processingPayment: false },
        type: UPDATE_REGISTRATION_STATE,
      });
      setUpdating(false);
    }
  };

  const registerDailyLoading =
    leagueLoading ||
    gameLoading ||
    userLoading ||
    enrollmentLoading ||
    dropInLoading ||
    dropInRsvpLoading;
  const registerDailyError =
    leagueError ||
    gameError ||
    userError ||
    enrollmentError ||
    dropInError ||
    programRosterError ||
    dropInRsvpError;

  return {
    cityName,
    confirmDropInRsvp,
    game,
    isActive,
    isEnrolledInProgram,
    isEnrolledRefetch,
    isMember,
    isRsvpdForDropIn,
    isVoloPassTrialActive,
    league,
    monthlyDollarPrice,
    monthlyFee,
    onStartVpClick,
    programRoster,
    programRosterRefetch,
    registerDailyError,
    registerDailyLoading,
    registrationClosed,
    registerMutation,
    registerUpdating,
    removeVpClick,
    twoWeeksLater,
  };
};

export default useRegistrationData;
