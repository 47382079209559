import { gql, useQuery } from '@apollo/client';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import RoleEnum from '../shared/role-enum';

/**
 * This hook returns the current user signed in as well as the current organizationId
 * that is being used (from the admin drop down or url params)
 * It also does organization validation to make sure the user has authorization to view the
 * selected organization
 */

const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      _id
      roles
      email
      firstName
      lastName
      gender
      homeOrganization
      organizationId
      organizations {
        _id
        name
      }
      player_strikes {
        count
      }
    }
  }
`;

const useCurrentUser = () => {
  const {
    loading,
    error,
    data = {},
    // TODO: this fetch policy does not seem necessary.
  } = useQuery(CURRENT_USER_QUERY, { fetchPolicy: 'network-only' });
  const { location } = useHistory() ?? {};

  if (loading || error) return { loading, error };
  if (!data?.currentUser) return { error: 'No logged in user found.' };

  const query = qs.parse(location?.search, { ignoreQueryPrefix: true });
  const {
    currentUser,
    currentUser: { roles, organizationId: curUserOrganizationId, organizations = [] } = {},
  } = data;

  let organizationId = curUserOrganizationId || organizations?.[0]?._id;
  const queryOrgId = query?.organization || organizationId;

  if (!queryOrgId || queryOrgId === organizationId) {
    return {
      currentUser: {
        ...currentUser,
        organizationId,
      },
      organizationId,
      isAuthenticated: !!currentUser,
      roles: currentUser.roles,
    };
  }

  // will add more checks as permissions grow
  const orgIds = organizations.map(({ _id }) => _id);
  // city admin
  if (roles.includes(RoleEnum.OWNER) && orgIds.includes(queryOrgId)) {
    organizationId = queryOrgId;
  }
  // super admin
  if (roles.includes(RoleEnum.ADMIN)) {
    organizationId = queryOrgId;
  }

  return {
    currentUser: {
      ...currentUser,
      organizationId,
    },
    isAuthenticated: !!currentUser,
    organizationId,
    roles,
  };
};

export default useCurrentUser;
