import PropTypes from 'prop-types';
import { CYCLIC_PROP } from '../common-props';

const SponsorBarProps = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
  details: PropTypes.string,
  formatted_address: PropTypes.string,
  isTbd: PropTypes.bool,
});

const RatingSurveyProps = PropTypes.shape({
  enabled: PropTypes.bool,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      questionType: PropTypes.string,
      customTypeQuestion: PropTypes.string,
    })
  ),
});

export const AvailableDivisionProps = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
  teams: PropTypes.arrayOf(CYCLIC_PROP),
  capacity: PropTypes.number,
  skillLevel: PropTypes.string,
  permanent: PropTypes.bool,
});

const LeagueProps = PropTypes.shape({
  _id: PropTypes.string,
  name: PropTypes.string,
  displayName: PropTypes.string,
  status: PropTypes.string,
  stage: PropTypes.string,
  teams: PropTypes.arrayOf(CYCLIC_PROP),
  sport: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    PropTypes.string, // leagues returned from SL queries
  ]),
  schedules: PropTypes.arrayOf(CYCLIC_PROP),
  start_date: PropTypes.string,
  startDateStr: PropTypes.string,
  header: PropTypes.string,
  price_phase: PropTypes.string,
  days_of_week: PropTypes.arrayOf(PropTypes.string),
  venue: CYCLIC_PROP,
  venueId: PropTypes.string,
  organization_name: PropTypes.string,
  organization: CYCLIC_PROP,
  groups: PropTypes.arrayOf(CYCLIC_PROP),
  freeAgents: PropTypes.arrayOf(CYCLIC_PROP),
  registration: CYCLIC_PROP,
  registration_notes: PropTypes.string,
  organizationId: PropTypes.string,
  teams_approved: PropTypes.bool,
  schedule_approved: PropTypes.bool,
  hasDivisions: PropTypes.bool,
  divisionDescriptions: PropTypes.arrayOf(PropTypes.string),
  whyYouPlayEnabled: PropTypes.bool,
  details: PropTypes.shape({
    description: PropTypes.string,
    perks: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    details: PropTypes.string,
    week_descriptions: PropTypes.arrayOf(PropTypes.string),
    week_tags: PropTypes.arrayOf(PropTypes.string),
    rules: PropTypes.string,
    sponsor_bar: SponsorBarProps,
  }),
  contact: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    textable: PropTypes.bool,
  }),
  isPrivate: PropTypes.bool,
  activityName: PropTypes.string,
  seasonName: PropTypes.string,
  archived: PropTypes.bool,
  gender: PropTypes.string,
  photoUrl: PropTypes.string,
  sponsor_bar: SponsorBarProps,
  donationsDisabled: PropTypes.bool,
  donateAllProceeds: PropTypes.bool,
  programType: PropTypes.string,
  featured_order: PropTypes.number,
  banner_text: PropTypes.string,
  neighborhood: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    organizationId: PropTypes.string,
  }),
  allEmails: PropTypes.arrayOf(PropTypes.string),
  playerSurvey: RatingSurveyProps,
  captainSurvey: RatingSurveyProps,
  expenses: PropTypes.shape({
    tshirtCostPerPlayer: PropTypes.number,
    insuranceCostPerPlayer: PropTypes.number,
    marketingCostPerPlayer: PropTypes.number,
    numReferees: PropTypes.number,
    refereeCostPerGame: PropTypes.number,
    numHosts: PropTypes.number,
    hostCostPerHour: PropTypes.number,
    customCosts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        cost: PropTypes.number,
        frequency: PropTypes.string,
      })
    ),
  }),
  isDaily: PropTypes.bool,

  customNotesMandatory: PropTypes.bool,
  customNotesPrompt: PropTypes.string,
  customQuestionsList: PropTypes.arrayOf(
    PropTypes.shape({
      questionTitle: PropTypes.string,
      isMandatoryQuestion: PropTypes.bool,
      isFreeText: PropTypes.bool,
      isDropdown: PropTypes.bool,
      dropdownOptions: PropTypes.arrayOf(PropTypes.string),
    })
  ),

  venueName: PropTypes.string,
  city: PropTypes.string,

  signupsToday: PropTypes.number,
  signupMetrics: PropTypes.shape({
    totalCount: PropTypes.number,
    totalRevenue: PropTypes.number,
    individualCount: PropTypes.number,
    groupCaptainCount: PropTypes.number,
    privateCaptainCount: PropTypes.number,
    groupMemberCount: PropTypes.number,
    privateMemberCount: PropTypes.number,
    individualRevenue: PropTypes.number,
    groupCaptainRevenue: PropTypes.number,
    privateCaptainRevenue: PropTypes.number,
    groupMemberRevenue: PropTypes.number,
    privateMemberRevenue: PropTypes.number,
  }),
  teamCount: PropTypes.number,
  tournament: PropTypes.arrayOf(CYCLIC_PROP),

  stagedReoccuringDates: PropTypes.arrayOf(PropTypes.string),
  isReoccuringParent: PropTypes.bool,
  isReoccuringChild: PropTypes.bool,
  reoccuringChildren: PropTypes.arrayOf(
    PropTypes.shape({
      leagueId: PropTypes.string,
      dateOfProgram: PropTypes.string,
    })
  ),

  minMen: PropTypes.number,
  minWomen: PropTypes.number,
  minPlayers: PropTypes.number,
  suggestedMen: PropTypes.number,
  suggestedWomen: PropTypes.number,
  suggestedPlayers: PropTypes.number,
  staff: PropTypes.arrayOf(CYCLIC_PROP),
  registrants: PropTypes.arrayOf(PropTypes.string),
  registrantObj: PropTypes.arrayOf(CYCLIC_PROP),
  formatType: PropTypes.string,
  staffUsers: PropTypes.arrayOf(CYCLIC_PROP),
  deepLink: PropTypes.string,
  sportFormatDetails: PropTypes.shape({
    competitionLevels: PropTypes.arrayOf(
      PropTypes.shape({
        hidden: PropTypes.bool,
        level: PropTypes.string,
        description: PropTypes.string,
      })
    ),
  }),
  permits: PropTypes.arrayOf(CYCLIC_PROP),
  guessedPermits: PropTypes.arrayOf(CYCLIC_PROP),
  permitIds: PropTypes.arrayOf(PropTypes.string),
  timeframe: PropTypes.arrayOf(PropTypes.string),
  programTag: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
  }),
  availableDivisions: PropTypes.arrayOf(AvailableDivisionProps),
  divisionsActivated: PropTypes.bool,
  programTagId: PropTypes.string,
  waiver: PropTypes.string,
  allow_teams: PropTypes.bool,
  _creator: PropTypes.string,
  _updated: PropTypes.string,
});

export default LeagueProps;
