import getProgramTypesFromTabs from './utils/getProgramTypesFromTabs';
import TABS, { MainTabEnum, SubTabEnum } from '../../shared/discover-tabs-enum';
import { ProgramGenders } from '../../shared/gender-enum';
import { ProgramTypeEnum } from '../../shared/program-type-enum';

const initialTabObject = TABS.SPORTS;
const initialTab = initialTabObject.tab;
const initialSubTab = initialTabObject.subTabs[0];
const initialSubTabArray = initialTabObject.subTabs;

// Most of these types are derived from our JS enums, but converting them into
// TS enums would remove the need to do derivations here.
type MainTab = (typeof MainTabEnum)[keyof typeof MainTabEnum];
type SubTab = keyof typeof SubTabEnum;
type ProgramGender = keyof typeof ProgramGenders;
type DayOfWeek = 'M' | 'T' | 'W' | 'Th' | 'F' | 'S' | 'Su';
type ProgramType = keyof typeof ProgramTypeEnum;

export type DiscoverStore = {
  MainTabArray: MainTab[];
  SubTabArray: SubTab[];
  view: MainTab;
  subView: SubTab;
  isDaily: boolean;
  defaultProgramTypes: ProgramType[];
  currentPage: number;
  numPerPage: number;
  currentGender: ProgramGender | null;
  showMobileFilters: boolean;
  filters: {
    isVirtual: boolean;
    cityName: string | null;
    dateLow: number | null;
    dateHigh: number | null;
    timeLow: number;
    timeHigh: number;
    programTagIds: string[];
    selectedGenders: ProgramGender[];
    selectedVenueIds: string[];
    selectedDaysOfWeek: DayOfWeek[];
    selectedSportNames: string[];
    selectedSkillLevels: string[];
    selectedProgramTypes: ProgramType[];
    selectedNeighborhoodIds: string[];
    minimumMen: number;
    minimumWomen: number;
    minimumNonBinary: number;
    homeCityId: string;
  };
  filtersCollapsed: {
    selectedSportNames: boolean;
    selectedDaysOfWeek: boolean;
  };
  queryStatus: {
    isLoading: boolean;
    resultsCount: number;
  };
};

// default time range in minutes
export const defaultTimeRange = {
  timeLow: 0,
  timeHigh: 1410,
};

const initialStore: DiscoverStore = {
  MainTabArray: Object.keys(MainTabEnum) as MainTab[],
  SubTabArray: initialSubTabArray as SubTab[],
  view: initialTab as MainTab,
  subView: initialSubTab as SubTab,
  isDaily: initialSubTab === SubTabEnum.DAILY,
  defaultProgramTypes: getProgramTypesFromTabs(initialTab, initialSubTab),
  currentPage: 1,
  numPerPage: 9,
  currentGender: null,
  showMobileFilters: false,
  filters: {
    isVirtual: false,
    cityName: null, // null means no city filter - only used for virtual subtab
    dateLow: null,
    dateHigh: null,
    timeLow: defaultTimeRange.timeLow,
    timeHigh: defaultTimeRange.timeHigh,
    programTagIds: [],
    selectedGenders: [],
    selectedVenueIds: [],
    selectedDaysOfWeek: [],
    selectedSportNames: [],
    selectedSkillLevels: [],
    selectedProgramTypes: [],
    selectedNeighborhoodIds: [],
    minimumMen: 0,
    minimumWomen: 0,
    minimumNonBinary: 0,
    homeCityId: '',
  },
  filtersCollapsed: {
    selectedDaysOfWeek: true,
    selectedSportNames: true,
  },
  queryStatus: {
    isLoading: false,
    resultsCount: 0,
  },
};

export default initialStore;
