import { useQuery } from '@apollo/client';
import { LEAGUE_LIST, type LeagueListVars } from '../../hooks';
import { useDebounce } from '../../hooks';

export type LeagueSearchFilters = Omit<LeagueListVars['input'], 'nameSearch'>;

/**
 * Hook for running the league list query with a search input
 */
export const useLeagueSearch = (input: string, filters?: LeagueSearchFilters) => {
  const { omitArchived = false, pagination, ...rest } = filters || {};

  const { numPerPage = 50, pageNum = 1 } = pagination || {};

  const search = input.trim();

  const nameSearch = useDebounce(search, 400);

  const query = useQuery(LEAGUE_LIST, {
    skip: search.length < 4 || nameSearch.length < 4,
    variables: {
      input: {
        nameSearch,
        omitArchived,
        pagination: {
          pageNum,
          numPerPage,
        },
        ...rest,
      },
    },
  });

  return {
    ...query,
    leagues: query.data?.leagueList.leagues || [],
  };
};

export default useLeagueSearch;
