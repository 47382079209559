export const ReferralTypeEnum = Object.freeze({
  USER: 'user',
  CAMPAIGN: 'campaign',
});

// Constants for Volo Pass/membership trial length
export const MembershipTrialEnum = Object.freeze({
  TWO_WEEKS: 'TWO_WEEKS',
  FOUR_WEEKS: 'FOUR_WEEKS',
});

export const MembershipTrialDaysMap = Object.freeze({
  [MembershipTrialEnum.TWO_WEEKS]: 14,
  [MembershipTrialEnum.FOUR_WEEKS]: 28,
});

// For a future sprint, we should consider moving this to the Organization table
// in order to accomodate per-organization commitment periods.
export const MEMBERSHIP_MINIMUM_MONTHS = 3;
