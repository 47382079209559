import { gql } from '@apollo/client';

export const GET_USER_PAYMENTS_UPDATED = gql`
  query getUserPayments($userPaymentListInput: UserDetailListInput!) {
    userPayments(input: $userPaymentListInput) {
      payments {
        paymentId
        leagueId
        rentalId
        date
        net
        reason
        credits
        donation
        refund
        revenue
        status
        type
        isDropin
        manuallyAddedBy
        organizationId
        promoCode
      }
      totalPayments
      totalNet
      totalRefunds
      totalDonations
      totalCredits
      count
    }
  }
`;

export const ISSUE_REFUND = gql`
  mutation registrantIssueRefund($input: RegistrantIssueRefundInput!) {
    registrantIssueRefund(input: $input) {
      registrant {
        _id
      }
    }
  }
`;

export const REMOVE_WITHOUT_REFUND = gql`
  mutation registrantRemoveWithoutRefund($input: RegistrantRemoveWithoutRefundInput!) {
    registrantRemoveWithoutRefund(input: $input) {
      registrant {
        _id
      }
    }
  }
`;

export const CURRENT_USER_SELF_WITHDRAW = gql`
  mutation currentUserSelfWithdraw($input: CurrentUserSelfWithdrawInput!) {
    currentUserSelfWithdraw(input: $input) {
      registrant {
        _id
      }
    }
  }
`;

export const ADD_CREDITS = gql`
  mutation registrantAddCredits($input: RegistrantAddCreditsInput!) {
    registrantAddCredits(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const DELETE_CREDITS = gql`
  mutation registrantDeleteCredits($input: RegistrantDeleteCreditsInput!) {
    registrantDeleteCredits(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const USER_MEMBERSHIP_HISTORY_QUERY = gql`
  query userMembershipHistory($userId: ID!, $pagination: StripePaginationInput) {
    membershipInvoicesForUser(userId: $userId, pagination: $pagination) {
      membershipInvoices {
        stripeInvoiceId
        createdAt
        status
        total
      }
      hasMore
    }
  }
`;
