import { gql } from '@apollo/client';

/* Fragments */
const PromoCodeFragment = gql`
  fragment promoCodeFragment on PromoCode {
    promoCodeStr
    discountPercentage
    discountCents
  }
`;

const DropinPricingBreakdownFragment = gql`
  fragment dropinPricingBreakdownFragment on PricingBreakdown {
    programPriceCents
    priceCents
    promoDiscountCents
    supportFeeCents
    processingFeeCents
    processingFeePercentage
    techFeeCents
    totalFeeCents
    donationAmount
    totalCents
    totalChargeCents
    promoCode {
      ...promoCodeFragment
    }
  }
  ${PromoCodeFragment}
`;

/* Queries */
export const LEAGUE_QUERY = gql`
  query league($leagueId: ID!) {
    league(_id: $leagueId) {
      _id
      programType
      isDaily
      waiverId
      has_tournament
      num_weeks_estimate
      num_playoff_weeks_estimate
      days_of_week_estimate
      start_time_estimate
      end_time_estimate
      start_date
      startDateStr
      timezone
      weeks {
        _id
        notes
        weekTag
        week_num
        byes
      }
      organization {
        _id
        city
        contact_phone_number
        contact_email
        name
        icon
        logo
        is_dry
        isVoloPassTrialActive
        voloPassTiers {
          isActive
          monthlyDollarPrice
          monthlyFee
          monthlyPlanId
        }
        more_information {
          charity {
            website_url
            html
            prefer
          }
        }
      }
      featured_order
      banner_text
      name
      gender
      activityName
      seasonName
      whyYouPlayEnabled
      customQuestionsList {
        questionTitle
        isMandatoryQuestion
        isFreeText
        isDropdown
        dropdownOptions
      }
      donationsDisabled
      hasDivisions
      divisionDescriptions
      photoUrl
      details {
        description
        perks
        features
        week_descriptions
        week_tags
        rules
      }
      contact {
        name
        email
        phoneNumber
        textable
      }
      registration {
        _id
        registration_open
        registration_close
        freeAgentsAllowed
        freeAgentLimitsEnabled
        registrationOpenStr
        registrationCloseStr
        min_team_amount
        max_team_amount
        min_team_size
        max_team_size
        currentSize
        maxSize
        malesAllowed
        femalesAllowed
        limit_individual_registrations
        block_individual_registrations
        block_group_captain_registrations
        block_group_member_registrations
        block_prepaid_team_captain_registrations
        block_prepaid_team_member_registrations
        block_all_registrations
        jersey_colors {
          name
          hex
        }
        memberPrice
        currentPhase
        phases {
          earlyBird {
            allow
            individualPrice
            prepaidTeamPrice
            endDateStr
            startDateStr
            endDate
          }
          regular {
            individualPrice
            prepaidTeamPrice
            startDateStr
          }
          lastChance {
            allow
            individualPrice
            prepaidTeamPrice
            startDateStr
          }
        }
      }
      schedules {
        _id
        startDateStr
        num_weeks
        num_tournament_weeks
        has_tournament
        days_of_week
        startTimeStr
        endTimeStr
        timezone
        game_length_in_minutes
        num_simultaneous_games
        num_games_per_team_per_week
        weeks {
          weekTag
          week_num
        }
      }
      venue {
        _id
        name
        neighborhood
        city
        zip
        formatted_address
        timezone
      }
      neighborhood {
        _id
        name
      }
      sponsor_bar {
        name
        formatted_address
        isTbd
      }
      groups {
        _id
        name
        prepaid
        isLocked
        autolockEnabled
        autolockThreshold
        color {
          name
          hex
        }
        captain {
          _id
          firstName
          lastName
          displayNameTeams
        }
      }
      deepLink
      sportFormatDetails {
        competitionLevels {
          level
          description
        }
      }
      formatType
    }
  }
`;

export const GET_GAME_DETAILS = gql`
  query game($gameId: ID!) {
    game(_id: $gameId) {
      _id
      league {
        _id
        gender
        organization {
          isVoloPassTrialActive
        }
        registration {
          registration_open
          registration_close
        }
      }
    }
  }
`;

export const GET_DROP_IN_SLOT = gql`
  query DropInSlot($id: ID!) {
    dropInSlot(_id: $id) {
      gameId
    }
  }
`;

export const GROUP_QUERY = gql`
  query group($groupId: ID!) {
    group(_id: $groupId) {
      _id
      name
      prepaid
      password_hint
      isLocked
      league {
        _id
      }
      registrants {
        _id
      }
    }
  }
`;

export const PROGRAM_PRICING = gql`
  query programPricing($input: ProgramPricingForRegistrationInput!) {
    programPricingForRegistration(input: $input) {
      pricingBreakdown {
        priceCents
        promoDiscountCents
        supportFeeCents
        processingFeeCents
        processingFeePercentage
        totalFeeCents
        techFeeCents
        donationAmount
        creditAmount
        totalCents
        membershipDiscountApplied
        memberPrice
        nonMemberPrice
        originalPrice
        promoCode {
          ...promoCodeFragment
        }
      }
      promoCodeMessage
    }
  }
  ${PromoCodeFragment}
`;

export const DROPIN_PRICING = gql`
  query dropinPricingForRegistration($input: DropinPricingForRegistrationInput!) {
    dropinPricingForRegistration(input: $input) {
      nonmemberBreakdown {
        ...dropinPricingBreakdownFragment
      }
      memberBreakdown {
        ...dropinPricingBreakdownFragment
      }
      currentUserPaymentCriteria
      promoCodeMessage
    }
  }
  ${DropinPricingBreakdownFragment}
`;

export const CURRENT_USER_DATA = gql`
  query currentUserPaymentSources {
    currentUser {
      _id
      gender
      chosenPlan
      membershipExpires
      availableCredits {
        organizationId
        amount
        used
      }
      hasStripeId
      paymentSources {
        last4
        isDefault
        isExpired
        exp_month
        exp_year
        brand
        id
      }
    }
  }
`;

export const ACTIVE_MEMBERSHIP = gql`
  query activeMembership {
    activeMembership {
      status
    }
  }
`;

export const ACTIVE_RECURRING_DONATION = gql`
  query UserIsActiveDonor {
    userIsActiveDonor
  }
`;
