/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState } from 'react';
import useLeagueSearch, { type LeagueSearchFilters } from './useLeagueSearch';
import FilterSelect from '../FilterSelect';
import type { LeagueListData } from '../../hooks';
import type { FilterSelectProps } from '../FilterSelect/types';

/** League option object */
export type LeagueOption = LeagueListData['leagueList']['leagues'][number];

/** `FilterSelect` props with league option data type 😵‍💫 */
type LeagueFilterSelectProps<isMulti extends boolean> = Omit<
  FilterSelectProps<LeagueOption, isMulti>,
  'inputValue' | 'options' | 'onInputChange'
> & {
  placeholder?: string;
  leagueFilters?: LeagueSearchFilters;
};

const LeagueFilterSelect = <isMulti extends boolean = false>({
  border = true,
  leagueFilters,
  noOptionsMessage,
  filterOption = null,
  placeholder = 'Type or paste program name',
  ...props
}: LeagueFilterSelectProps<isMulti>) => {
  const [input, setInput] = useState('');
  const { leagues, loading, error, data } = useLeagueSearch(input, leagueFilters);

  const handleNoOptionsMessage: NonNullable<LeagueFilterSelectProps<isMulti>['noOptionsMessage']> =
    useCallback(
      ({ inputValue }) => {
        // Handle error if it exists
        if (error) return `There was an error. ${error?.message}`;
        if (inputValue.length === 0) return 'Start typing to match leagues..';
        // It was specifically spec'd that we don't fire the query until 4 characters have been entered.
        if (inputValue.length < 4) return 'Keep typing to match leagues..';
        // If the input is still debouncing, display loading state
        if (data === undefined) return 'Loading...';
        // Here there are truly no matches
        return 'No matches found';
      },
      [error, data]
    );

  return (
    <FilterSelect
      {...props}
      border={border}
      inputValue={input}
      isLoading={loading}
      options={leagues}
      onInputChange={setInput}
      placeholder={placeholder}
      filterOption={filterOption}
      getOptionValue={(option: LeagueOption) => option._id}
      getOptionLabel={(option: LeagueOption) => option.name}
      noOptionsMessage={noOptionsMessage || handleNoOptionsMessage}
    />
  );
};

export default LeagueFilterSelect;
