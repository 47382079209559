import useDiscoverStore from '../DiscoverStore';
import { defaultTimeRange } from '../initialDiscoverStore';

const get = useDiscoverStore.getState;

export const DateFormat = 'YY/MM/DD';

const checkIfFiltersAreApplied = () => {
  const { filters } = get();
  const {
    dateLow,
    dateHigh,
    timeLow,
    timeHigh,
    programTagIds,
    minimumMen,
    minimumWomen,
    selectedGenders,
    minimumNonBinary,
    selectedVenueIds,
    selectedDaysOfWeek,
    selectedSportNames,
    selectedSkillLevels,
    selectedProgramTypes,
    selectedNeighborhoodIds,
  } = filters;

  let filtersAreApplied = false;

  if (dateLow) filtersAreApplied = true;
  if (dateHigh) filtersAreApplied = true;
  if (timeLow !== defaultTimeRange.timeLow) filtersAreApplied = true;
  if (timeHigh !== defaultTimeRange.timeHigh) filtersAreApplied = true;
  if (programTagIds.length) filtersAreApplied = true;
  if (selectedGenders.length) filtersAreApplied = true;
  if (selectedVenueIds.length) filtersAreApplied = true;
  if (selectedDaysOfWeek.length) filtersAreApplied = true;
  if (selectedSportNames.length) filtersAreApplied = true;
  if (selectedSkillLevels.length) filtersAreApplied = true;
  if (selectedProgramTypes.length) filtersAreApplied = true;
  if (selectedNeighborhoodIds.length) filtersAreApplied = true;
  if (minimumMen) filtersAreApplied = true;
  if (minimumWomen) filtersAreApplied = true;
  if (minimumNonBinary) filtersAreApplied = true;

  return filtersAreApplied;
};

export default checkIfFiltersAreApplied;
