import React from 'react';
import * as _ from 'lodash-es';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const ErrorMessage = ({ error, errors, warning, message, success, style }) => {
  const alert = error || errors || warning || message || success;

  if (
    !alert ||
    !alert.length ||
    (Array.isArray(alert) && (!_.compact(alert) || !_.compact(alert).length))
  )
    return <span />;

  return (
    <div>
      <div
        style={style}
        className={cx('message', {
          'warning-message': warning,
          'normal-message': message,
          'success-message': success,
          'error-message': error || errors,
        })}
      >
        {Array.isArray(alert) ? (
          <span>
            {_.map(alert, (msg, i) => (
              <p className={cx('err-msg')} key={`er${i}`}>
                {msg}
              </p>
            ))}
          </span>
        ) : (
          <span>
            <p className={cx('err-msg')}>{alert}</p>
          </span>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
